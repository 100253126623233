.margin-2px{
    margin: 2px;
}

.div-flex{
    display: flex;
}


.marginTopAndLeft{
    margin-top: 1px;
    margin-bottom: 1px;
    margin-left: 2px;
}

.amenities-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .amenity {
    display: flex;
    align-items: center; /* Align icon and text vertically */
    flex-grow: 1;
  }
  
  .amenity p {
    margin-left: 5px; /* Add margin between icon and text */
  }
  
  /* .amenity:nth-child(1) > svg,
  .amenity:nth-child(4) > svg,
  .amenity:nth-child(2) > svg,
  .amenity:nth-child(5) > svg,
  .amenity:nth-child(3) > svg,
  .amenity:nth-child(6) > svg {
    margin-top: 0;
  } */
  
  .back-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 9%;
    margin-bottom: 20px;
    cursor: pointer;
  }
  
  .rdrDay{
    line-height: 0;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    width: 30vw;
  }
  
  .close {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 40px;
    color: #1668e3;
  }
  
  .modal-content {
    padding: 20px;
  }
  
  .traveler-button{
    border: 1px solid black;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .disableButton{
    opacity: 0.6;
  cursor: not-allowed;
  }

  .divWithIconAndPara{
    display: flex;
  align-items: center;
  width: 50%;
  justify-content: flex-start;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-left: 10px;
  height: 30px;
  }

  .divWithIconAndPara p{
    margin-left: 10px;
  }

  .reserve-button{
    background-color: #1668e3;
    color: white;
    /* border-radius: 50%; */
    padding: 7px 20px 7px 20px;
    height: 50px;
    border-radius: 30px;
    border-color: #1668e3;
    font-weight: bold;
  }