@tailwind base;
@tailwind components;
@tailwind utilities;
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-datepicker {
  border: 2px solid #ccc; /* Example border style */
  border-radius: 4px; /* Example border radius */
  padding: 8px; /* Example padding */
  font-size: 16px; /* Example font size */
  /* Add any other custom styles as needed */
}
.amplify-select {
  outline: none !important;
  background-color: transparent !important;
   /* border-style: none !important; */
   --amplify-components-fieldcontrol-padding-inline-start: 1px;
   --amplify-components-fieldcontrol-padding-block-start: 1px;
   --amplify-components-select-padding-inline-end: 15px;
  
 }

 .custom-amplify-flex{
  gap: 0px !important;
 
 }
 .amplify-select__wrapper {
  flex: none !important;
  
}
.amplify-input--large{
  border-radius: 64px !important;
}
.amplify-tabs__list {
  border-style: none !important;
}

.amplify-field-group__inner-end .amplify-button{
  /* height: 70% !important */
  margin-block: '6px' !important;
 border: '36px solid blue' !important;
  width: 70% !important;
  border-radius: '36px' !important;
}
.rdrDay{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.custom-datepicker{

}
.react-datepicker__view-calendar-icon input{
  border: '1px solid red' !important;
}
/* To specify background color and font size */
.e-input-group .e-input-group-icon:last-child, .e-input-group.e-control-wrapper .e-input-group-icon:last-child {
  font-size: 12px;
  background-color: darkgray;
}
/* To specify height and font size */
.e-input-group input.e-input, .e-input-group.e-control-wrapper input.e-input {
  height: 40px;
  font-size: 20px;
}
.rdrDateDisplayItem  {
  display: none;
}
 
.rdrDateDisplayWrapper{
  background-color: white !important;
}
.rdrCalendarWrapper {
  width: 500px;
}


 .map{
  position: absolute;
	top: 0;
	bottom: 0;
  right: 0px;
	width: 40vw;
  overflow-y: hidden;
 }

 .thinks-map{
  width: 90vw;
  height: 300px;
  overflow-y: hidden;

 }

 /* .mapboxgl-popup-content, .maplibregl-popup-content{
  background-color: #f39f9f !important;
  font-size: 18px; 
  height: 40px;
 } */


 /* .amplify-flex{
  background-color: green !important;
 } */
 .amplify-button--primary{
  background-color: #007aff !important;

 }
 #signIn-tab{
  color: #007aff !important;
 }
 #signUp-tab{
  color: #007aff !important;
 }
 .amplify-button--link{
  color: #007aff !important;
 }
 /* .amplify-text{
  color: #007aff !important;

 } */

