@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family:  'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}
#root {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  /* padding: 2rem; */
  text-align: center;
  /* background-color: #f2f4f7; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* .modal-overlay{
  background-color: green;
  border-radius: 8px;
  width: 24rem;
  height: 14rem;
  z-index: 1;
  padding: 4px;
  position: 'absolute'
} */
.modal-header{
  display: flex;
  justify-content: space-between;
  
}
.modal-body{
  display: flex;
  justify-content: space-between;
  margin-block: 1rem;
}
.modal-header {
  display: flex;
  align-items: center;
  margin-block: 0.4rem;
}

.modal-title {
 width: 100%;
  text-align: end;
}

.close-button {
  background: none;
  border: none;
  width: 100%;
  font-size: 14px;
  cursor: pointer;
  text-align: end;
}
.button{
  border: none;
} 
.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.recomendation{
  gap: 0px !important
}
.modal{
  z-index: 100;
  align-items: center;
  justify-content: center;
}
.checkout-card{
  box-shadow: 2px 2px 2px 2px hsla(210, 50%, 10%, 0.25) !important;
}
.blur {
  filter: blur(50px) !important; 
}
.flight-details{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white; /* semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* ensure it's on top of everything */
}

/* In your styles.css or a Tailwind CSS file if not using Tailwind */

.filter {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  overflow-y: auto;
  max-height: 100vh; /* Ensure it doesn't overflow the viewport */
}

.select__control{
  height: 56px !important;
  background-color: transparent !important;
}