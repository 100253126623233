/* styles.css */

.address-form-container {
    max-width: 400px;
    margin: 0 auto;
  }
  
  .address-form-container div {
    margin-bottom: 20px;
  }
  
  .address-form-container p {
    margin-bottom: 5px;
    text-align: left;
  }
  
  .address-form-container input[type="text"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .address-form-container .address-input {
    width: 100%; /* Set the width to 100% */
    padding: 8px; /* Match padding with other inputs */
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .address-form-container span.error {
    display: block;
    color: red;
    margin-top: 5px;
  }
  
  .address-form-container button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .address-form-container button.cancel {
    background-color: #dc3545;
  }
  
  .address-form-container button.submit {
    margin-left: 10px;
  }
  